<template>
    <footer class="d-flex justify-content-between">
        <div v-if="showGoalsNotFound">
            <b-button :disabled="loading" @click="onClickGoalsNotfound" variant="outline-info" class="mr-1">Objetivo não <br>encontrado</b-button>
            <b-button :disabled="loading" @click="$emit('cancel')" variant="outline-secondary">Cancelar</b-button>
            <b-button :disabled="loading" @click="$emit('clear')" variant="link">
                {{ label }}
            </b-button>
        </div>
        <div v-else-if="!hideSaveGoals" class="d-flex w-25">
            <b-form-input v-model="goalName" placeholder="Informe o nome do objetivo" />
            <b-button @click="saveGoalsNotfound" variant="info" class="ml-1">Salvar</b-button>
        </div>
        <b-button :disabled="loading" @click="$emit('confirm')" variant="info">Confirmar</b-button>
    </footer>
</template>

<script>
export default {
    props: {
        label: { type: String, default: 'Limpar classificações' },
        hideSaveGoals: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    data () {
        return {
            showGoalsNotFound: true,
            goalName: ''
        }
    },
    methods: {
        onClickGoalsNotfound () {
            this.showGoalsNotFound = false
            this.$emit('goals-notfound')
        },
        saveGoalsNotfound () {
            this.$emit('goals-save', this.goalName)
        }
    }
};
</script>