import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/project${_parans}`)
  },
  showAll: () => {
    return _http.get(`/api/project/auto-complete?size=999999&asc`)
  },
  autoComplete: (search) => {
    return _http.get(`/api/project/auto-complete?page=${0}&search=${search}&asc`)
  },
  find: (id) => {
    return _http.get(`/api/project/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/project', payload)
  },
  update: (payload) => {
    return _http.put('/api/project', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/project/${id}`)
  },
  showEvents: (id) => {
    return _http.get(`/api/project/${id}/events`)
  },
  showLessons: (id) => {
    return _http.get(`/api/project/${id}/lessons`)
  },
  showProjectTeachers: (search) => {
    return _http.get(`/api/project/teachers?page=${0}&search=${search}&asc`)
  },
  findByCompany: (id) => {
    return _http.get(`/api/project/find-by-company/${id}`)
  },
  autoCompleteByCompany: (search, id) => {
    return _http.get(`/api/project/auto-complete-by-company?page=${0}&search=${search}&data.company_id=${id}&asc`)
  },
  makePublicProject: (id) => {
    return _http.post(`/api/project/${id}/makepublicproject`)
  },
  findByCompanySystemDefault: (id) => {
    return _http.get(`/api/project/find-by-company-system-default/${id}`)
  },
  getGoals: (Req) => {
    const params = new URLSearchParams()
    params.append('page', Req?.page || 1)
    params.append('size', Req?.perPage || 15)

    if (Req?.companyId) params.append('data.company_id', Req.companyId)
    if (Req?.search) params.append('search', Req.search)

    return _http.get(`/api/project/monolito/get-goals`, { params })
  },
  getClassificationByGoalsIDs: (Req) => {
    const params = new URLSearchParams()
    if (Req?.companyId) params.append('data.company_id', Req.companyId)
    if (Req?.goalId) params.append('data.goal_id', Req.goalId)

    return _http.get(`/api/project/monolito/get-goals-classification`, { params })
  },
  getClassificationRootByCompanyID: (companyId) => {
    return _http.get(`/api/project/monolito/get-categories-for-goals/${companyId}`)
  },
  getCategoriesForGoals: (company_id) => {
    return _http.get(`/api/project/monolito/get-categories-for-goals/${company_id}`)
  },
  getNamesClassifications: (id) => {
    return _http.get(`/api/project/get-classification-names-by-project/${id}`)
  },
  getClassifications: (Req) => {
    const params = new URLSearchParams()
    params.append('page', Req?.page || 1)
    params.append('size', Req?.perPage || 15)

    if (Req?.categoryId) params.append('data.category_id', Req.categoryId)
    if (Req?.companyId) params.append('data.company_id', Req.companyId)
    if (Req?.search) params.append('search', Req.search)

    return _http.get(`/api/project/monolito/get-classification-by-category`, { params })
  },
  saveGoals: (payload) => {
    return _http.post(`/api/gols-not-found`, { payload })
  },
}