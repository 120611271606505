<template>
    <b-modal content-class="modal-classification" footer-bg-variant="modal-footer" no-close-on-backdrop @hide="$emit('close')" visible
        :title="title" centered size="xl">
        <template #default>
            <classification-header 
                :title="title" 
                :tabs="displayTabs" 
                :loading="hasLoadingInTabs" 
                @tab-clicked="onClickTab" 
            />

            <classification-results
                :items="displayResults"
                :type-items="displayResultsType"
                :loading-more-items="isLoadingMoreItems"
                :has-more-items="displayResultsHasMoreItems"
                :loading-classifications="hasLoadingInClassifications"
                :classifications-selected="displayClassificationsSelected"
                @load-more-classifications="onClickLoadMoreClassifications" 
                @load-more-goals="onClickLoadMoreGoals"
                @item-goals-clicked="onClickGoals"
                @item-classifications-clicked="onClickItemClassifications"
                @remove-clicked="onClickRemove"
                @open-children-clicked="onClickClassificationsOpen"
                @search-classifications="onSearchingClassifications"
                @search-goals="onSearchingGoals"
            />
        </template>

        <template #modal-footer>
            <classification-footer
                :loading="hasLoadingInFooter"
                :hide-save-goals="projectId <= 0"
                @goals-notfound="onClickGoalsNotfound"
                @confirm="onClickConfirm" 
                @cancel="onClickCancel" 
                @clear="onClickClear" 
                @goals-save="onClickGoalSave"
            />
        </template>
    </b-modal>
</template>
<script>
import classificationHeader from '@/components/classification/classification-header.vue';
import classificationResults from '@/components/classification/classification-results.vue';
import classificationFooter from '@/components/classification/classification-footer.vue';

import projectAPI from "@/services/projects-service";

import { TAB_TYPES } from '@/helpers/constants.js'

export default {
    components: {
        classificationFooter,
        classificationHeader,
        classificationResults
    },
    props: {
        isOpen: { type: Boolean, default: true },
        title: { type: String, default: 'Classificar' },
        companyId: { type: Number, default: 0 },
        projectId: { type: Number, default: 0 },
        classificationRoot: { Type: Object, default: null }
    },
    computed: {
        hasLoadingInTabs() {
            return this.goals.loading || this.classifications.loadingRoot
        },
        displayTabs() {
            return this.tabs.filter(f => f.show) ?? []
        },
        displayResults() {
            return this.results ?? []
        },
        displayResultsType() {
            return this.tabCurrent?.type ?? TAB_TYPES.GOALS
        },
        displayResultsHasMoreItems() {
            return this.resultsHasMoreItems
        },
        hasLoadingInClassifications() {
            return this.classifications.loading || this.goals.loading
        },
        hasLoadingInFooter() {
            return this.hasLoadingInTabs || this.hasLoadingInClassifications || this.isLoadingMoreItems || this.isLoadingSaveGoals
        },
        displayClassificationsSelected() {
            return this.classifications.selected
        }
    },
    data() {
        return {
            tabs: [{ name: 'Objetivos', id: 'goals', type: TAB_TYPES.GOALS, show: true }],
            tabCurrent: null,
            resultsHasMoreItems: false,
            results: [],
            isLoadingMoreItems: false,
            isLoadingSaveGoals: false,

            goals: {
                loading: false,
                perPage: 15,
                page: 1,
                hasMoreItems: false,
                items: [],
                selected: [],
                search: null
            },
            classifications: {
                loadingRoot: false,
                loading: false,
                perPage: 15,
                page: 1,
                hasMoreItems: false,
                roots: [],
                selected: [],
                items: [],
                extra: false,
                search: null
            }
        }
    },
    async created() {
        await Promise.all([
            this.loadGoals(),
            this.loadClassificationsRoot()
        ])
        this.init()
    },
    methods: {
        init() {
            this.fillResults(this.goals.items, this.goals.hasMoreItems)
            this.tabCurrent = this.tabs[0]

            if (this.classificationRoot?.length > 0) {
                this.classifications.selected = this.classificationRoot
                this.classifications.roots = this.classificationRoot
            }
            this.classifications.extra = false
        },
        fillResults(items, moreItems) {
            this.resultsHasMoreItems = moreItems
            this.results = items
        },
        async loadGoals() {
            if (this.goals.loading || this.isLoadingMoreItems) return

            this.goals.page > 1 
            ? this.isLoadingMoreItems = true 
            : this.goals.loading = true
            try {
                const { content } = await projectAPI.getGoals({
                    perPage: this.goals.perPage,
                    page: this.goals.page,
                    search: this.goals.search,
                    companyId: this.companyId
                })

                if (content?.data?.goals?.length) {
                    this.goals.hasMoreItems = content.data.goals.length >= this.goals.perPage
                    this.goals.items = [...this.goals.items, ...(content.data.goals ?? [])]
                }
            } catch (error) {
                this.$utils.toastError("Erro ao buscar objetivos", error)
            } finally {
                this.isLoadingMoreItems= false 
                this.goals.loading = false
            }
        },
        async loadClassificationsRoot() {
            try {
                const { content } = await projectAPI.getCategoriesForGoals(this.companyId)

                if (content?.data?.length) {
                    this.classifications.roots = content.data

                    this.tabs = this.tabs.filter(f => f.type != TAB_TYPES.CLASSIFICATIONS)
                    const tabsRoots = this.classifications.roots.map(m => {
                        return {
                            type: TAB_TYPES.CLASSIFICATIONS,
                            classification_id: m.id,
                            children: [],
                            show: false,
                            ...m
                        }
                    })
                    this.tabs = [...this.tabs, ...tabsRoots]
                }
            } catch (error) {
                this.$utils.toastError("Erro ao buscar classificações Root", error)
            }
        },
        async onClickGoals(goal) {
            if (this.goals.selected.some(g => g.goal.id === goal.id)) {
                this.goals.selected = this.goals.selected.filter(f => f.goal.id !== goal.id)
            } else {
                const classifications = await this.loadClassificationsByGoals(goal.id)

                this.goals.selected.push({
                    classifications,
                    goal
                })
            }

            // combine classifications of objectives
            const goalClassifications = this.getClassificationsSelected()

            // display for users
            if(goalClassifications.length === 0) return

            this.classifications.selected = []
            
            this.classifications.roots.forEach(root => {
                this.classifications.selected.push({
                    children: goalClassifications.filter(f => f.parent_id === root.id),
                    name: root.name,
                    id: root.id
                })
            })
        },
        async loadClassificationsByGoals(goalId) {
            this.classifications.loading = true
            try {
                const { content } = await projectAPI.getClassificationByGoalsIDs({
                    companyId: this.companyId,
                    goalId: goalId
                })

                return content?.data ?? []
            } catch (error) {
                this.$utils.toastError("Erro ao buscar classificações", error)
            } finally {
                this.classifications.loading = false
            }
        },
        async onClickLoadMoreGoals() {
            this.goals.page++
            await this.loadGoals()

            this.fillResults(this.goals.items, this.goals.hasMoreItems)
        },
        async onClickLoadMoreClassifications() {
            this.classifications.page++

            let tab = this.tabs.find(f => f.id === this.tabCurrent.id)

            const classificationsRs = await this.loadClassificationsChildren(tab.classification_id, this.classifications.page)

            tab.children = [...tab.children, ...classificationsRs]

            this.fillResults(tab.children, classificationsRs.length >= this.classifications.perPage)
        },
        async onClickTab(tab) {
            this.tabCurrent = tab;
            switch (tab.type) {
                case TAB_TYPES.GOALS:
                    this.tabs.filter(f => f.type != TAB_TYPES.GOALS).forEach(tab => tab.show = false)
                    this.fillResults(this.goals.items, this.goals.hasMoreItems)
                    break

                case TAB_TYPES.CLASSIFICATIONS:
                    if(tab.children?.length <= 0) {
                        this.fillResults([], false)
                        this.classifications.page = 1
                    
                        tab.children = await this.loadClassificationsChildren(tab.classification_id, this.classifications.page)
                    }

                    const isLoadMore = tab.children?.length >= this.classifications.perPage
                    this.fillResults(tab.children, isLoadMore)
                    break
            }
        },
        getClassificationsSelected() {
            const classificationsSelected = this.classifications.selected.reduce((newArray, item) => {
                item.children.forEach(classification => {
                    if(!newArray.some(s => s.id === classification.id)) {
                        newArray.push(classification)
                    }
                });
                return newArray;
            }, [])

            const classificationsGoalSelected = this.goals.selected.reduce((newArray, item) => {
                item.classifications.forEach(classification => {
                    if(!newArray.some(s => s.id === classification.id)) {
                        newArray.push(classification)
                    }
                });
                return newArray;
            }, [])

            const classifications = [...classificationsSelected, ...classificationsGoalSelected]

            return classifications.reduce((newArray, item) => {
                if(!newArray.some(s => s.id === item.id)) {
                    newArray.push(item)
                }
                return newArray;
            }, [])
        },
        onClickConfirm() {
            this.$emit('confirm', {
                classificationsRoot: this.classifications.selected,
                classifications: this.getClassificationsSelected()
            })
        },
        onClickCancel() {
            this.$emit('close')
        },
        onClickClear() {
            this.classifications.selected = []
        },
        onClickRemove(classification) {
            const classifications = this.classifications.selected.map(item => {
                return {
                    children: item.children.filter(f => f.id !== classification.id),
                    name: item.name,
                    id: item.id
                }
            })
            this.classifications.selected = classifications
        },
        async onClickGoalsNotfound() {
            this.classifications.extra = true
            this.classifications.page = 1
            this.fillResults([], false)

            for (let i = 0; i < this.tabs.length; i++) {
                this.tabs[i].show = this.tabs[i].type === TAB_TYPES.CLASSIFICATIONS
            }

            let firtTab = this.tabs.find(f => f.show)
            if (firtTab) {
                firtTab.children = await this.loadClassificationsChildren(firtTab.classification_id, this.classifications.page)

                const isLoadMore = firtTab.children.length >= this.classifications.perPage
                this.fillResults(firtTab.children, isLoadMore)

                this.tabCurrent = firtTab
            }
        },
        async loadClassificationsChildren(classification_id, page) {
            if (this.classifications.loading || this.isLoadingMoreItems) return

            page > 1 
                ? this.isLoadingMoreItems = true 
                : this.classifications.loading = true

            try {
                const { content } = await projectAPI.getClassifications({
                    perPage: this.classifications.perPage,
                    page: page,
                    categoryId: classification_id,
                    companyId: this.companyId,
                    search: this.classifications.search
                })

                return content?.data?.map(m => {
                    return {
                        extra: this.classifications.extra,
                        parent_id: classification_id,
                        loading: false,
                        isOpen: false,
                        children: [],
                        ...m,
                    }
                })
            } catch (error) {
                this.$utils.toastError("Erro ao buscar classificações", error)
            } finally {
                this.classifications.loading = false
                this.isLoadingMoreItems = false
            }
        },
        onClickItemClassifications (item) {
            if (item.isRootLess) {
                if (!this.classifications.selected.some(s => s.id === TAB_TYPES.ROOT_LESS)) {
                    this.classifications.selected.push({ name: 'Outras', id: TAB_TYPES.ROOT_LESS, children: [] })
                }

                let rootLess = this.classifications.selected.find(f => f.id == TAB_TYPES.ROOT_LESS)
                
                if (rootLess.children.some(s => s.id === item.id)) {
                    rootLess.children = rootLess.children.filter(f => f.id !== item.id)
                } else {
                    rootLess.children.push(item)
                }
                return
            }

            if (!this.classifications.selected.some(s => s.id === item.parent_id)) {
                this.classifications.selected.push({
                    name: this.tabCurrent.name,
                    id: this.tabCurrent.id,
                    children: []
                 })
            }

            const classifications = this.classifications.selected.map(selected => {
                if (selected.id == this.tabCurrent.id) {
                    if (!selected.children.some(s => s.id === item.id)) {
                        selected.children.push(item)
                    }
                }
                
                return {
                    children: selected.children,
                    name: selected.name,
                    id: selected.id
                }
            })

            this.classifications.selected = classifications
        },
        async onClickClassificationsOpen(parent) {
            parent.isOpen = !parent.isOpen
            parent.loading = true

            try {
                if (parent.children?.length > 0) { return }

                parent.children = await this.loadClassificationsChildren(parent.id, 1)
            } catch (error) {
                this.$utils.toastError("Erro ao buscar classificações filhas", error)
            } finally {
                parent.loading = false
            }
        },
        async onSearchingGoals(searchTerm) {
            this.goals.hasMoreItems = false
            this.fillResults([], this.goals.hasMoreItems)

            this.goals.search = searchTerm
            this.goals.items = []
            this.goals.page = 1

            await this.loadGoals()
            this.fillResults(this.goals.items, this.goals.hasMoreItems)
        },
        async onSearchingClassifications(searchTerm) {
            this.classifications.search = searchTerm
            this.classifications.items = []
            this.classifications.page = 1
            this.fillResults([], false)

            let tab = this.tabs.find(f => f.id === this.tabCurrent.id)

            tab.children = await this.loadClassificationsChildren(tab.classification_id, this.classifications.page)

            this.fillResults(tab.children, tab.children.length >= this.classifications.perPage)
        },
        async onClickGoalSave (name) {
            this.isLoadingSaveGoals = true
            try {
                await projectAPI.saveGoals({
                    project_id: this.projectId,
                    name: name
                })
            } catch (error) {
                this.$utils.toastError("Erro ao salvar objetivo", error)
            } finally {
                this.isLoadingSaveGoals = false
            }

        }
    }
}
</script>

<style>
.modal-body {
    padding: 0rem 1.4rem;
}

.modal-footer {
    display: initial;
    background: #fafafa;
}
</style>