<template>
    <div :style="nodeMargin">
        <div class="d-flex">
            <b-form-checkbox :value="node" @input="$emit('click-item', node)"/>
            <feather-icon
                v-if="node.has_children"
                :icon="node.isOpen ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                @click="$emit('open-children', node)"
                size="18"
            />
            <strong>{{ node.name }}</strong>
        </div>
        <span class="text-muted ml-3" v-if="node.loading">Carregando...</span>
        <div v-show="node.isOpen && !node.loading">
            <tree-node
                v-for="child in node.children"
                :key="child.id"
                :node="child"
                :spacing="spacing + 10"
                @open-children="value => $emit('open-children', value)"
                @click-item="value => $emit('click-item', { ...value, isRootLess: true })"
            />
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TreeNode',
    props: {
      node: { type: Object, required: true },
      spacing: { type: Number, default: 0 }
    },
    computed: {
      nodeMargin() {
        return {
          'margin-left': `${this.spacing}px`
        }
      }
    }
  }
  </script>