<template>
    <header>
        <b-skeleton v-if="loading" animation="throb" class="mt-1" />
        <b-tabs v-else>
            <b-tab 
                v-for="(tab, idx) in tabs"
                :key="idx" 
                @click="$emit('tab-clicked', tab)"
            >
                <template #title>
                    <strong >{{ tab.name }}</strong>
                </template>
            </b-tab>
        </b-tabs>
       
    </header>
</template>

<script>
import { BSkeleton, BTabs, BTab } from "bootstrap-vue";
export default {
    components: {
        BSkeleton,
        BTabs,
        BTab
    },
    props: {
        loading: { type: Boolean, default: false },
        tabs: { type: Array, default: [] }
    }
};
</script>