import { render, staticRenderFns } from "./classification-modal.vue?vue&type=template&id=98ac2b10&"
import script from "./classification-modal.vue?vue&type=script&lang=js&"
export * from "./classification-modal.vue?vue&type=script&lang=js&"
import style0 from "./classification-modal.vue?vue&type=style&index=0&id=98ac2b10&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports