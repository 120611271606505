<template>
    <div>
    <b-row>
        <b-col md="5">
            <b-form-group>
                <b-form-input v-model="search" placeholder="Buscar" :debounce="500" />
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col md="5" class="block-list">
            <tree-node 
                v-for="node in display" 
                :key="node.id"
                :node="node"
                @open-children="onClickClassificationOpen"
                @click-item="onClick"
            />

            <p v-if="display.length === 0 && !hasLoading">Nenhum registro encontrado</p>

            <b-spinner type="grow" v-if="hasLoading" />
            <b-button
                v-else-if="hasMoreItems"
                size="sm" 
                class="my-1" 
                variant="info"
                @click="onClickLoadMore"
            >
                Carregar mais
            </b-button>
        </b-col>
        <b-col md="7" style="border-left: 1px solid #eee;">
            <div>
                <strong> {{ totalClassificationsSelected > 0 
                    ? `${totalClassificationsSelected} classificações selecionadas` 
                    : 'Nenhuma classificação selecionada' }}
                </strong>
                <hr class="p-0">
                
                <b-row v-for="(classification, idx) in displayClassifications" :key="`${idx}-${classification.name}`">
                    <b-col md="12"><strong>{{ classification.name }}</strong></b-col>
                    <b-col class="mb-1">
                        <b-badge v-for="(child, ichd) in classification.children" :key="`${ichd}-${child.name}`" class="mr-50 mb-1" variant="light-secondary">
                            <div class="cursor-pointer" @click="onClickRemove(child)">
                                <strong class="pr-1">{{ child.name }}</strong>
                                <font-awesome-icon :icon="['fas', 'xmark']" />
                            </div>
                        </b-badge>
                    </b-col>
                </b-row>
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
import { BSkeletonTable, BSkeleton } from 'bootstrap-vue'
import TreeNode from './TreeNode.vue'

export default {
    components: {
        BSkeletonTable,
        BSkeleton,
        TreeNode
    },
    data() {
        return {
            search: ''
        }
    },
    props: {
        items: { type: Array, default: [] },
        typeItems: { type: String, required: true },
        hasMoreItems: { type: Boolean, default: false },
        loadingMoreItems: { type: Boolean, default: false },
        loadingClassifications: { type: Boolean, default: false },
        classificationsSelected: { type: Array, default: [] },
    },
    watch: {
        async search() {
            this.onSearching()
        }
    },
    computed: {
        display() {
            return this.items ?? []
        },
        displayClassifications() {
            return this.classificationsSelected
        },
        totalClassificationsSelected() {
            return this.classificationsSelected.reduce((total, item) => total + (item.children?.length ?? 0), 0)
        },
        hasLoading () {
            return this.loadingMoreItems || this.loadingClassifications
        }
    },
    methods: {
        onClick(item) {
            this.$emit(`item-${this.typeItems}-clicked`, item)
        },
        onClickLoadMore() {
            this.$emit(`load-more-${this.typeItems}`)
        },
        onClickRemove(classification) {
            this.$emit(`remove-clicked`, classification)
        },
        onClickClassificationOpen(classificationParent) {
            this.$emit('open-children-clicked', classificationParent)
        },
        onSearching () {
            this.$emit(`search-${this.typeItems}`, this.search)
        }
    }
}
</script>

<style>
.block-list {
    max-height: 450px;
    overflow-y: auto;
}
</style>